import slugify from "@/utils/slugifyString";

export const useMenu = (data) => {
  // console.log(data);
  const menu = [];
  const highlights = [];
  const menuParent = data?.filter((item) => item.highlight);
  if (!data?.length) return { menu, menuParent };
  const menuChildren = data.filter(
    (item) =>
      item.danh_muc_me !== "VIETTHIEN" &&
      item.danh_muc_me !== "" &&
      !item.highlight
  );

  menuParent.map((item) =>
    highlights.push({
      ...item,
      title: item.ten_danh_muc,
      href: `/mua-hang/${item.slug}?cat=${item.ma_danh_muc}|${menuChildren
        .filter((cat) => cat.danh_muc_me === item.ma_danh_muc)
        .map((el) => el.ma_danh_muc)
        .join("|")}&page=1`,
      subMenu: {
        subMenuLeft: [],
        subMenuRight: [],
      },
    })
  );
  menuChildren.map((item) => {
    const { ten_danh_muc_me } = item;
    const positionParent = menu.map((e) => e.title).indexOf(ten_danh_muc_me);
    if (positionParent >= 0) {
      menu[positionParent].subMenu.subMenuLeft.push({
        ...item,
        title: item.ten_danh_muc,
        href: `/mua-hang/${slugify(item.ten_danh_muc_me)}?cat=${
          item.ma_danh_muc
        }&page=1`,
      });
      if (item.logo || item.image) {
        menu[positionParent].subMenu.subMenuRight.push({
          ...item,
          title: item.ten_danh_muc,
          href: `/mua-hang/${slugify(item.ten_danh_muc_me)}?cat=${
            item.ma_danh_muc
          }&page=1`,
          image: item.logo || item.image,
        });
      }
    }
  });

  // menu.unshift({
  //   title: "Sản phẩm mới",
  //   href: `/collections/san-pham-moi?cat=${menuChildren
  //     .map((el) => el.ma_danh_muc)
  //     .join("|")}&sort=newest`,
  // });
  // menu.push(
  //   {
  //     title: "Khuyến mãi",
  //     href: `/collections/san-pham-moi?cat=${menuChildren
  //       .map((el) => el.ma_danh_muc)
  //       .join("|")}&filterBy=promotion`,
  //   },
  //   {
  //     title: "Liên hệ",
  //     href: "/lien-he",
  //   }
  // );

  return { menu, menuParent, highlights };
};
