import { Box, createStyles, Text } from "@mantine/core";
import Link from "next/link";
import React from "react";

const useStyles = createStyles((theme) => ({
  text: {
    // lineHeight: 30,
    position: "relative",
    transition: ".3s",
    color: theme.colors.second[2],
    cursor: "pointer",

    "&:before": {
      content: "''",
      height: "2px",
      backgroundColor: "currentColor",
      opacity: ".3",
      transition: "all .3s",
      position: "absolute",
      bottom: 0,
      left: "20%",
      width: "60%",
    },
    "&:hover": {
      color: "#2f2415",
      "&:before": {
        backgroundColor: "#2f2415",
        transform: "scale(160%, 100%)",
      },
    },
  },
}));

function TextShowMore({ children, href }) {
  const { classes } = useStyles();
  return (
    <Box>
      <Link href={href || "#"}>
        <Text
          size="sm"
          className={classes.text}
          transform="uppercase"
        >{children}</Text>
      </Link>
      {/* <Link scroll={false} href={href || "#"}>
        <a>
          <Text
            // size={{ xs: "sm", sm: "md" }}
            size="sm"
            color={theme => theme.colors.second[2]}
            className={classes.text}
            transform="uppercase"
          >
            {children}
          </Text>
        </a>
      </Link> */}
    </Box>
  );
}

export default TextShowMore;
