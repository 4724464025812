import { useFetchEcompage } from "@/apis/queryFunctions/ecompagesFunctions";
import useTrans from "@/hooks/useTrans";
import appendImageUrlFromAPI from "@/utils/appendImageUrlFromAPI";
import { Box } from "@mantine/core";
import Image from "next/image";
import { useRouter } from "next/router";
import React from "react";
import HomeTitle from "../Typography/HomeTitle";

export default function HomePromotion() {
  const trans = useTrans();
  const { locale } = useRouter();
  const { data } = useFetchEcompage("du-lieu-chung-cafe", locale);
  const info = data?.add_on_data?.[2] || {};

  return (
    <Box component="section">
      {/* <Title order={2} size="h2" weight="700" color={theme.colors.second[3]} align="center">
            <Text>
                {info?.content ? sanitizeDOMData(info?.content): info?.title}
            </Text>
        </Title> */}
      <HomeTitle>{trans?.homePromotion.title}</HomeTitle>
      <Box
        component="section"
        sx={{
          position: "relative",
          width: "100%",
          aspectRatio: "3",
          overflow: "hidden",
        }}
      >
        <Image
          src={appendImageUrlFromAPI({ src: info?.picture })}
          layout="fill"
          objectFit="cover"
        />
      </Box>
    </Box>
  );
}
