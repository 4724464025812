import appendImageUrlFromAPI from "@/utils/appendImageUrlFromAPI";
import { Card, createStyles, Group, Skeleton, Text } from "@mantine/core";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";

const useStyles = createStyles(() => ({
  imageWrapper: {
    width: "100%",
    aspectRatio: "1.5",
    position: "relative",
  },
}));

function NewCard({ item, isLoading = false }) {
  const { classes } = useStyles();
  const { locale } = useRouter();

  return (
    <Card shadow="sm" p={0} radius="sm" sx={{ height: "100%",position:"relative" }}>
      <Link scroll={false} href={`/tin-tuc/${item?.slug}` || "#"}>
        <a>
          <Skeleton visible={isLoading}>
            <Card.Section
            // component="a"
            >
              <div className={classes.imageWrapper}>
                {!isLoading && (
                  <Image
                    src={appendImageUrlFromAPI({
                      src: item?.picture,
                      size: "s",
                    })}
                    alt="Norway"
                    layout="fill"
                    objectFit="contain"
                  />
                  )}
                
              </div>
            </Card.Section>
          </Skeleton>
          <Group p="md">
            <Skeleton visible={isLoading}>
              <Text
                style={{ letterSpacing: 1 }}
                transform="uppercase"
                size="sm"
                lineClamp={2}
                weight="500"
              >
                {item?.title ||
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."}
              </Text>
            </Skeleton>
            {/* <Space h={"sm"} w="100%" /> */}
            <Skeleton visible={isLoading}>
              <Text size="xs">
                {new Date(item?.date_updated).toLocaleDateString(locale, {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                })}
              </Text>
            </Skeleton>
          </Group>
        </a>
      </Link>
      {item?.thong_tin_km &&
        <img src={"/images/khuyen_mai.png"}
          style={{width:64,top:-10,right:-10,position:"absolute"}}
          />
      }
    </Card>
  );
}

export default NewCard;
