import { useFetchEcompage } from "@/apis/queryFunctions/ecompagesFunctions";
import sanitizeDOMData from "@/utils/sanitizeDOMData";
import { Box, Title } from "@mantine/core";
import { useRouter } from "next/router";
import React from "react";
import HomeTitle from "../Typography/HomeTitle";
import Link from "next/link";
import { useMediaQuery } from "@mantine/hooks";

function Slogan() {
  const { locale } = useRouter();
  const { data } = useFetchEcompage("du-lieu-chung-cafe", locale);

  const slogan = data?.add_on_data?.[0] || {};
  const slogan2 = data?.add_on_data?.[3] || {};

  const matches = useMediaQuery("(max-width: 768px)");

  return (
    <Box component="section">
      <Box
        sx={(theme) => ({
          margin: "70px 0 20px",
          [theme.fn.smallerThan("md")]: {
            margin: "30px 5px",
          },
        })}
      >
        <Link href="#" passHref>
          <a>
            <Title
              order={2}
              size="h2"
              align="center"
              w="100#"
              sx={(theme) => ({
                transition: "0.3s",
                color: theme.colors.second[3],
                "&:hover": {
                  color: theme.colors.second[3],
                },
              })}
            >
              {sanitizeDOMData(matches ? slogan2?.content : slogan?.content)}
            </Title>
          </a>
        </Link>
      </Box>
    </Box>
  );
}

export default Slogan;

{
  /* <Box
  sx={(theme) => ({
    transition: "0.3s",
    margin: "70px 0 20px",
    [theme.fn.smallerThan("md")]: {
      margin: "30px 5px",
    },
  })}
>
  <Link href="#" passHref>
    <a>
      <Title align="center">{sanitizeDOMData(slogan2?.content)}</Title>
      <Title align="center">{sanitizeDOMData(slogan3?.content)}</Title>
    </a>
  </Link>
</Box>; */
}
