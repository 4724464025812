import {
  HOME_BANNER_OPTIONS,
  fetchBanners,
} from "@/apis/queryFunctions/banner";
// import { useFetchCategories } from "@/apis/queryFunctions/categoryFunctions";
import {
  fetchCategories,
  useFetchCategories,
} from "@/apis/queryFunctions/categoryFunctions";
import {
  fetchEcompage,
  useFetchEcompage,
} from "@/apis/queryFunctions/ecompagesFunctions";
// import { useMenu } from "@/apis/queryFunctions/menu";
import {
  DEFAULT_FETCHING_NEWS_OPTIONS,
  fetchNewsArticles,
} from "@/apis/queryFunctions/news";
import {
  fetchProducts,
  getBestSellerOptions,
} from "@/apis/queryFunctions/productFunctions";
import {
  categoryKeys,
  ecompagesKeys,
  newsKeys,
  productKeys,
} from "@/apis/queryKeys";
import bannersKeys from "@/apis/queryKeys/bannersKeys";
import Container from "@/common/Layout/Container/Container";
// import BestSellers from "@/common/components/BestSellers";
import DefaultSEO from "@/common/components/DefaultSEO";
import HomeAbout from "@/common/components/HomeAbout";
import HomeBanner from "@/common/components/HomeBanner";
import HomeLiked from "@/common/components/HomeLiked";
import HomeNews from "@/common/components/HomeNews";
import HomePromotion from "@/common/components/HomePromotion";
import Slogan from "@/common/components/Slogan";
import { Box } from "@mantine/core";
import { QueryClient, dehydrate } from "@tanstack/react-query";
import React from "react";
import BestSellers from "@/common/components/BestSellers";
import { useMenu } from "@/apis/queryFunctions/menu";
import { useRouter } from "next/router";
import PageContainer from "@/common/components/PageContainer";
import { WhyChooseHome } from "@/common/components";

export default function Home({ seo }) {
  // console.log(111111,seo)
  const { data: allCategories } = useFetchCategories();
  const { highlights } = useMenu(allCategories);

  function getCollectionHref(maDanhMuc) {
    if (!menu || menu.length === 0) return "";
    const item = menu.find((el) => el.ma_danh_muc === maDanhMuc);
    if (!item) return "";
    return item.href;
  }

  const { locale } = useRouter();

  const { data: detail } = useFetchEcompage("trang-gioi-thieu-cafe", locale);

  return (
    <Box component="div" pb="lg">
      <DefaultSEO seo={seo} />
      <HomeBanner />
      <Container>
        <Slogan />
        <HomeAbout />
        <HomeLiked />
      </Container>
      <HomePromotion />

      <Container fluid>
        <Box sx={{ zIndex: 2 }}>
          {Boolean(detail) && (
            <PageContainer>
              <WhyChooseHome item={detail?.add_on_data_2} isHomeScreen />
            </PageContainer>
          )}
        </Box>
      </Container>
      <HomeNews />
    </Box>
  );
}

export async function getStaticProps({ locale }) {
  const queryClient = new QueryClient();

  const newsFetchingOptions = {
    ...DEFAULT_FETCHING_NEWS_OPTIONS,
    condition: {
      ...DEFAULT_FETCHING_NEWS_OPTIONS.condition,
      ngon_ngu: locale,
    },
    notfields: ["content"],
  };

  await queryClient.prefetchQuery(newsKeys.list(newsFetchingOptions), () =>
    fetchNewsArticles(newsFetchingOptions)
  );

  /* -------------------------------------------------------------------------- */
  const categoryLv1Ids = [
    "TRALAI",
    "TRATUILOC",
    "TRASAMDUA",
    "TRABAC",
    "TRAOLONG",
    "TRAMOCCAU",
  ];

  categoryLv1Ids.map((item) => {
    async function PrefetchQuery(id) {
      await queryClient.prefetchQuery(productKeys.homeList(id), () =>
        fetchProducts({
          options: getBestSellerOptions(id),
        })
      );
    }
    return PrefetchQuery(item);
  });

  // await queryClient.prefetchQuery(productKeys.homeList(categoryLv1Ids[0]), () =>
  //   fetchProducts({
  //     options: getBestSellerOptions(categoryLv1Ids[0]),
  //   })
  // );

  // await queryClient.prefetchQuery(productKeys.homeList(categoryLv1Ids[1]), () =>
  //   fetchProducts({
  //     options: getBestSellerOptions(categoryLv1Ids[1]),
  //   })
  // );
  // await queryClient.prefetchQuery(productKeys.homeList(categoryLv1Ids[2]), () =>
  //   fetchProducts({
  //     options: getBestSellerOptions(categoryLv1Ids[2]),
  //   })
  // );
  /* -------------------------------------------------------------------------- */
  await queryClient.prefetchQuery(bannersKeys.list("home-top-sliders"), () =>
    fetchBanners(HOME_BANNER_OPTIONS)
  );
  await queryClient.prefetchQuery(
    ecompagesKeys.detail("du-lieu-chung-cafe"),
    () => fetchEcompage("du-lieu-chung-cafe", locale)
  );
  await queryClient.prefetchQuery(categoryKeys.all, () =>
    fetchCategories({ condition: { highlight: true } })
  );

  const seoData = await fetchEcompage("du-lieu-chung-cafe", locale);

  return {
    props: {
      dehydratedState: dehydrate(queryClient),
      seo: {
        title: seoData?.meta_title || "ZeMor Coffee",
        description: seoData?.meta_description || null,
        keywords: seoData?.meta_keywords || null,
        picture1: seoData?.meta_picture_1 || null,
        picture2: seoData?.meta_picture_2 || null,
      },
    },
    revalidate: 60,
  };
}
