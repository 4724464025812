import styled from "@emotion/styled";
import { Box } from "@mantine/core";
import React from "react";
import HomeTitle from "./HomeTitle";
import TextShowMore from "./TextShowMore";

const Wrapper = styled.div`
  display: flex;
  justify-content: ${(props) => (props.hrefRight ? "space-between" : "center")};
  align-items: center;

  @media (max-width: 576px) {
    flex-direction: column;
    margin-bottom: 8px;
  }
  margin: 30px 0;
  @media (max-width: 576px) {
    margin: 0;
  }
`;

function HomeRow({
  labelLeft,
  labelRight,
  hrefRight,
  hrefLeft,
  hasDecorate = false,
}) {
  return (
    <Box my="md">
      <Wrapper hrefRight={hrefRight}>
        <HomeTitle href={hrefLeft || "#"} hasDecorate={hasDecorate}>
          {labelLeft}
        </HomeTitle>
        <TextShowMore href={hrefRight}>{labelRight}</TextShowMore>
        {/* {!hrefLeft ?
          : <></>
        } */}
      </Wrapper>
    </Box>
  );
}

export default HomeRow;
