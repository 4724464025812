import { useFetchHomePageProducts } from "@/apis/queryFunctions/productFunctions";
import { Box } from "@mantine/core";
import { FiChevronLeft } from "@react-icons/all-files/fi/FiChevronLeft";
import { FiChevronRight } from "@react-icons/all-files/fi/FiChevronRight";
import useEmblaCarousel from "embla-carousel-react";
import React from "react";
import ProductCard from "../ProductCard";
import HomeRow from "../Typography/HomeRow";

export default function BestSellers({
  labelLeft,
  labelRight,
  hrefLeft,

  title,
  href,
  ma_danh_muc_cty,
  except,
}) {
  const { data: products, isLoading } = useFetchHomePageProducts({
    ma_danh_muc_cty,
  });

  const [sliderRef, sliderAPI] = useEmblaCarousel({
    loop: true,
    align: "start",
    dragFree: true,
  });

  const [prevBtnEnabled, setPrevBtnEnabled] = React.useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = React.useState(false);

  const onSelect = React.useCallback(() => {
    if (!sliderAPI) return;
    setPrevBtnEnabled(sliderAPI.canScrollPrev());
    setNextBtnEnabled(sliderAPI.canScrollNext());
  }, [sliderAPI]);

  React.useEffect(() => {
    if (!sliderAPI) return;

    sliderAPI.reInit();
  }, [sliderAPI]);

  React.useEffect(() => {
    if (!sliderAPI || !onSelect) return;
    onSelect();
    sliderAPI.on("select", onSelect);
  }, [sliderAPI, onSelect]);

  const scrollPrev = React.useCallback(() => {
    if (sliderAPI) sliderAPI.scrollPrev();
  }, [sliderAPI]);

  const scrollNext = React.useCallback(() => {
    if (sliderAPI) sliderAPI.scrollNext();
  }, [sliderAPI]);

  return (
    !!products?.length > 1 && (
      <Box py={20}>
        <HomeRow labelLeft={title} hrefLeft={href} />

        <div className="bestsellers-list">
          <div className="bestsellers-list__viewport" ref={sliderRef}>
            <div className="bestsellers-list__container">
              {(isLoading ? [{}, {}, {}, {}, {}, {}] : products)
                .filter((el) => el.ma_vt !== except)
                .map((product) => (
                  <div key={product._id} className="bestsellers-list__slide">
                    <ProductCard product={product} isLoading={isLoading} />
                  </div>
                ))}
            </div>
          </div>
          <button
            className="bestsellers-list__button bestsellers-list__prev"
            onClick={scrollPrev}
            type="button"
            disabled={!prevBtnEnabled}
          >
            <FiChevronLeft />
          </button>
          <button
            className="bestsellers-list__button bestsellers-list__next"
            onClick={scrollNext}
            type="button"
            disabled={!nextBtnEnabled}
          >
            <FiChevronRight />
          </button>
        </div>
      </Box>
    )
  );
}
