import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useRouter } from "next/router";
import { asyncGetList } from "../fetch";
import bannersKeys from "../queryKeys/bannersKeys";

const DEFAULT_STALE_TIME = 60000 * 5; // 5min

export const DEFAULT_BANNER_OPTIONS = {
  sort: {
    stt: 1,
  },
  limit: 5,
  condition: {
    status: true,
    shared: true,
  },
};

export const HOME_BANNER_OPTIONS = {
  ...DEFAULT_BANNER_OPTIONS,
  condition: {
    ...DEFAULT_BANNER_OPTIONS.condition,
    vi_tri: "home-top-sliders-cafe",
  },
};

export const fetchBanners = async (options = {}) =>
  asyncGetList({
    collection_name: "ecombanners",
    options,
  });

export const useFetchBanners = (options = {}) => {
  const queryClient = useQueryClient();
  const { locale } = useRouter();

  const mergeOptions = {
    ...options,
    condition: {
      ...(options?.condition || {}),
      ngon_ngu: locale,
    },
  };

  return useQuery(
    bannersKeys.list(mergeOptions),
    () => fetchBanners(mergeOptions),
    {
      enabled: !!options?.condition?.vi_tri,
      staleTime: DEFAULT_STALE_TIME,
      onSuccess: (banners) => {
        banners.map((banner) => {
          queryClient.setQueriesData(bannersKeys.detail(banner?._id), banner);
        });
      },
    }
  );
};
