import { useFetchCategories } from "@/apis/queryFunctions/categoryFunctions";
import useTrans from "@/hooks/useTrans";
import appendImageUrlFromAPI from "@/utils/appendImageUrlFromAPI";
import { Box, Grid, Text } from "@mantine/core";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";
import HomeTitle from "../Typography/HomeTitle";

export default function HomeLiked() {
  const { data } = useFetchCategories({
    condition: {},
  });

  //limit 4 item homelike
  if (data && data?.length > 4) {
    data.splice(4, data.length - 4);
  }

  const trans = useTrans();
  const { locale } = useRouter();

  return (
    <Box component="section">
      <HomeTitle>{trans?.homeLiked.title}</HomeTitle>
      <Box component="section">
        <Grid gutter={30} justify="center">
          {Boolean(data) &&
            data.map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Grid.Col
                key={index}
                xs={6}
                sm={6}
                sx={{
                  width: "100%",
                  cursor: "pointer",
                  transition: "1s",
                  // filter: "blur(1px)",
                  // "&:hover": {
                  //   backgroundColor: "#00723B",
                  //   opacity: 0.6,
                  //   // filter: "opacity(.5)",
                  //   filter: "alpha(opacity=50)",
                  // },
                }}
              >
                <Link href={`/danh-muc-san-pham/${item?.slug}?sort=all`}>
                  <Box
                    sx={{
                      position: "relative",
                      aspectRatio: "1.5",
                      width: "100%",
                      // borderRadius: 8,
                      borderTopLeftRadius: 40,
                      borderBottomRightRadius: 144,
                      overflow: "hidden",

                      // height: 526,
                      // backgroundColor: "#00723B",
                      "&:hover": {
                        // backgroundColor: "#00723B",
                        // opacity: 0.5,
                        // filter: "opacity(.5)",
                        // filter: "alpha(opacity=0)",
                      },
                    }}
                  >
                    <Image
                      src={appendImageUrlFromAPI({ src: item?.logo })}
                      layout="fill"
                      objectFit="cover"
                    />
                    <Box
                      sx={{
                        position: "relative",
                        aspectRatio: "1.5",
                        width: "100%",
                        borderRadius: 8,
                        overflow: "hidden",
                        transition: "1s",
                        "&:hover": {
                          backgroundColor: "#00723B",
                          opacity: 0.5,
                        },
                      }}
                    />
                    <Box
                      p={8}
                      sx={(theme) => ({
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        width: "100%",
                        height: "100%",
                        transform: "translate(-50%, -50%)",
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        flexDirection: "column",
                      })}
                    >
                      <Box
                        sx={(theme) => ({
                          width: "65%",
                          height: "35%",
                          display: "flex",
                          alignItems: "flex-end",
                        })}
                      >
                        <Box
                          sx={(theme) => ({
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundImage: theme.fn.radialGradient(
                              "#491f08 ",
                              "#73300d 50%",
                              "transparent"
                            ),
                            padding: "0.5rem 0",
                          })}
                        >
                          <Text
                            size={"1.5rem"}
                            sx={(theme) => ({
                              "@media (max-width: 48em)": {
                                fontSize: "1.2rem",
                              },

                              opacity: 0.8,
                              width: "100%",
                            })}
                            align="center"
                            color="#fff"
                            weight="bold"
                          >
                            {locale === "vi"
                              ? item?.ten_danh_muc
                              : item?.ten_danh_muc_eng}
                          </Text>
                        </Box>
                      </Box>
                      <Box
                        sx={(theme) => ({
                          height: "65%",
                          width: "100%",
                          justifyContent: "center",
                          display: "flex",
                        })}
                      >
                        <Text
                          size={16}
                          sx={{
                            width: "80%",
                            "@media (max-width: 50em)": {
                              fontSize: "0.8rem",
                            },
                            textShadow: "0 0 5px #000",
                          }}
                          // lineClamp={4}
                          align="center"
                          color="#fff"
                          weight="bold"
                        >
                          {locale === "vi"
                            ? item?.mo_ta_ngan
                            : item?.mo_ta_ngan_eng}
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                </Link>
              </Grid.Col>
            ))}
        </Grid>
      </Box>
    </Box>
  );
}
