import { useQuery, useQueryClient } from "@tanstack/react-query";
import { asyncGetItem, asyncGetList } from "../fetch";
import { newsKeys } from "../queryKeys";

const DEFAULT_STALE_TIME = 1000 * 60 * 30; // 30 min

export const DEFAULT_FETCHING_NEWS_OPTIONS = {
  limit: process.env.NEWS_LOADING_LIMIT,
  page: 1,
  condition: {
    ngon_ngu: "vi",
    loai_tin_tuc: "Tin tức cà phê",
  },
};

export const fetchNewsArticle = async (slug) =>
  await asyncGetItem({
    collection_name: "news",
    options: {
      shared: true,
      condition: {
        slug,
      },
    },
  });

export const useFetchNewsArticle = (slug) =>
  useQuery(newsKeys.detail(slug), () => fetchNewsArticle(slug), {
    // staleTime: DEFAULT_STALE_TIME,
    enabled: !!slug,
  });

/* -------------------------------------------------------------------------- */

export const fetchNewsArticles = async (options = {}) =>
  await asyncGetList({
    collection_name: "news",
    options: {
      page: 1,
      limit: process.env.NEWS_LOADING_LIMIT,
      sort: {
        stt:1,date_created: -1,
      },
      ...options,
      condition: {
        isnews: true,
        ngon_ngu: "vi",
        shared: true,
        ...(options?.condition || {}),
      },
    },
  });

export const useFetchNewsArticles = (options) => {
  const queryClient = useQueryClient();

  return useQuery(newsKeys.list(options), () => fetchNewsArticles(options), {
    enabled: !!options,
    staleTime: DEFAULT_STALE_TIME,
    onSuccess: (newsArticles) => {
      newsArticles.map((newsArticle) => {
        queryClient.setQueriesData(
          newsKeys.detail(newsArticle?.slug),
          newsArticle
        );
      });
    },
  });
};

/* -------------------------------------------------------------------------- */

export const fetchNewsArticlesCount = async (options = {}, locale) => {
  const data = await asyncGetList({
    collection_name: "news",
    options: {
      count: true,
      condition: {
        ngon_ngu: locale,
        isnews: true,
        loai_tin_tuc: "Tin tức cà phê",
      },
    },
  });
  return data?.rows_number || 0;
};

export const useFetchNewsArticlesCount = (options, locale) =>
  useQuery(
    newsKeys.count(options?.condition || {}),
    () => fetchNewsArticlesCount(options, locale),
    {
      enabled: !!options,
      staleTime: DEFAULT_STALE_TIME,
    }
  );
